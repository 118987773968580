.go-tabs {
    width: 100%;
    display: flex;
    color: #8489ab;
}
.q-mb-md, .q-my-md {
    margin-bottom: 16px;
}
.q-mt-md, .q-my-md {
    margin-top: 16px;
}
.go-tabs>div.active {
    color: #212a64;
    border-bottom: 4px solid #212a64;
}
.go-tabs>div {
    flex-grow: 1;
    text-align: center;
    border-bottom: 2px solid #8489ab;
    cursor: pointer;
    padding: 0.5em;
    font-size: 1.2em;
    position: relative;
}
.go-tabs .q-icon {
    font-size: 1.5em;
    margin-right: 0.25em;
    width: 1.5em;
}
.q-icon {
    height: auto;
}
.material-icons, .material-icons-outlined, .material-icons-round, .material-icons-sharp, .q-icon {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: inherit;
    font-size: inherit;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}
.q-icon {
    line-height: 1;
    width: 1em;
    height: 1em;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    text-align: center;
    position: relative;
    box-sizing: content-box;
    fill: currentColor;
}
.company-profile .go-avatar {
    display: inline;
    font-size: 3.5em;
    margin-bottom: 0.25em;
}
.go-avatar.editable {
    cursor: pointer;
}
.go-avatar {
    color: #fff;
    position: relative;
    cursor: pointer;
}
.go-avatar .go-avatar-cover {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
}
.go-avatar .go-avatar-circle {
    width: 5.5em;
    height: 5.5em;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
}
.column, .flex {
    display: flex;
    flex-wrap: wrap;
}
.bg-primary {
    background: #212a64!important;
}
.go-avatar span {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 2.2em;
}
.company-profile .company-profile-items .company-stat {
    color: #8489ab;
    font-size: 1.1em;
    margin-top: 0.7em;
    font-weight: 500;
    margin-right: 1.1em;
}
.company-profile .company-profile-items .company-stat .q-icon {
    margin-right: 0.25em;
    margin-top: -0.3em;
}
.single-price {
  text-align: center;
  background: white;
  transition: .7s;
  margin-top: 20px;
}
.single-price h3 {
  font-size: 25px;
  color: #000;
  font-weight: 600;
  text-align: center;
  margin: 0;
  margin-top: -80px;
  font-family: poppins;
  color: #fff;
}
.single-price h4 {
  font-size: 30px;
  font-weight: 500;
  color: #fff;
}
.single-price h4 span.sup {
  vertical-align: text-top;
  font-size: 25px;
}
.deal-top {
  position: relative;
  background: #b3b0b0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
  padding: 80px 24px 0;
}
/*.deal-top::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -50px;
  width: 0;
  height: 0;
  border-top: 50px solid #212a64;
  border-left: 166px solid transparent;
  border-right: 145px solid transparent;
}*/
.deal-bottom {
  padding: 0px 16px 0;
}
.deal-bottom ul {
  margin: 0;
  padding: 0;
}
.deal-bottom  ul li {
  font-size: 16px;
  color: #000;
  font-weight: 300;
  margin-top: 16px;
  border-top: 1px solid #E4E4E4;
  padding-top: 16px;
  list-style: none;
}
.btn-area a {
  display: inline-block;
  font-size: 10px;
  color: #fff;
  background: #212a64;
  padding: 8px 64px;
  margin-top: 32px;
  border-radius: 4px; 
  margin-bottom: 40px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
}


.single-price:hover {
  background: #f5f5f5;
    border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.single-price:hover .deal-bottom ul li {
  color: #000;
}

.single-price:hover .deal-top {
  background: #272b60;
}
.single-price:hover .deal-top:after {
  border-top: 50px solid #272b60;
}
.single-price:hover .btn-area a {
  background: #212a64;
}





/* ignore the code below */


.link-area
{
  position:fixed;
  bottom:20px;
  left:20px;  
  padding:15px;
  border-radius:40px;
  background:tomato;
}
.link-area a
{
  text-decoration:none;
  color:#fff;
  font-size:25px;
}