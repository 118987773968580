.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #002045;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.flex{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  position: relative;
}
.square{
  width: 50px;
  height: 50px;
  background-color: #333;
  color: #FAFAFA;
  text-align: center;
  margin-right: 5px;
  cursor: pointer;
}
.square:hover{
  opacity: 0.8;
}
.rectangle{
  background-color: #333;
  margin: 10px 0;
  padding: 20px;
  width: 175px;
  color: #FAFAFA;
}
.img-absolute{
  position: absolute;
  left: 50%;
  top: 0;
  width: 56%;
}
.btn-download{
  display: inline-block;
  text-decoration: none;
  background-color: salmon;
  color: #FAFAFA;
  padding: 10px 62px;
  margin: 10px auto;
}
.btn-download:hover{
  opacity: 0.8;
}