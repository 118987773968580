    .radioStyle[type='radio']:after {
    width: 20px;
    height: 10px;
    border-radius: 10px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 0px solid white;
    }

    .radioStyle[type='radio']:checked:after {
    width: 20px;
    height: 10px;
    border-radius: 10px;
    top: -4px;
    left: -1px;
    position: relative;
    background-color: black;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 0px solid white;
    }

    .radioStyleOne[type='radio']:after {
    width: 20px;
    height: 10px;
    border-radius: 10px;
    top: -15px;
    left: -1px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid black;
    }

    .radioStyleOne[type='radio']:checked:after {
    width: 20px;
    height: 10px;
    border-radius: 10px;
    top: -15px;
    left: -1px;
    position: relative;
    background-color: black;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid black;
    }